/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
// Ensure all imports are at the top

import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';

import "./src/styles/bootstrap.min.css"
import "./src/styles/all.min.css"
import "./src/styles/global.scss"

export const onInitialClientRender = () => {
    const updateLangAttribute = (language) => {
        document.documentElement.lang = language || 'en'; // Default language if undefined
    };

    // Set the correct lang attribute when the site loads
    setTimeout(() => {
        const language = window.localStorage.getItem('gatsby-intl-language') || 'en';
        updateLangAttribute(language);
    }, 0);
};

export const wrapPageElement = ({ element }) => (
    <IntlContextConsumer>
        {({ language = 'en' } = {}) => (
            <>
                <Helmet>
                    <html lang={language} />
                </Helmet>
                {element}
            </>
        )}
    </IntlContextConsumer>
);